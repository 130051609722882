// // AptitudeDashboard.js
// import React, { useState } from 'react';
// import { List, ListItem, ListItemIcon, ListItemText, Box, Typography, Collapse } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSchool, faBriefcase, faChartLine, faMessage, faCode, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import './AptitudeDashboard.css';

// const dashboardItems = [
//   {
//     name: 'General Aptitude',
//     subtopics: [
//       { name: 'Arithmetic Aptitude', path: '/Arithmetic' },
//       { name: 'Data Interpretation', path: '/data-interpretation' },
//       { name: 'Online Aptitude Test', path: '/test/online-aptitude' },
//     ],
//     icon: <FontAwesomeIcon icon={faChartLine} style={{ color: "#FFFFFF" }} />,
//   },
//   {
//     name: 'Verbal & Reasoning',
//     subtopics: [
//       { name: 'Verbal Ability', path: '/verbal-ability' },
//       { name: 'Logical Reasoning', path: '/logical-reasoning' },
//       { name: 'Verbal Reasoning', path: '/verbal-reasoning' },
//     ],
//     icon: <FontAwesomeIcon icon={faSchool} style={{ color: "#FFFFFF" }} />,
//   },
//   {
//     name: 'Interview',
//     subtopics: [
//       { name: 'Placement Papers', path: '/placement-papers' },
//       { name: 'Group Discussion', path: '/group-discussion' },
//       { name: 'HR Interview', path: '/hr-interview' },
//     ],
//     icon: <FontAwesomeIcon icon={faBriefcase} style={{ color: "#FFFFFF" }} />,
//   },
//   {
//     name: 'Engineering',
//     subtopics: [
//       { name: 'Mechanical Engineering', path: '/mechanical-engineering' },
//       { name: 'Civil Engineering', path: '/test/civil-engineering' },
//       { name: 'Chemical Engineering', path: '/test/chemical-engineering' },
//     ],
//     icon: <FontAwesomeIcon icon={faLaptopCode} style={{ color: "#FFFFFF" }} />,
//   },
//   {
//     name: 'Programming',
//     subtopics: [
//       { name: 'C Programming', path: '/c-programming' },
//       { name: 'C++ Programming', path: '/cpp-programming' },
//       { name: 'Java Programming', path: '/java-programming' },
//     ],
//     icon: <FontAwesomeIcon icon={faCode} style={{ color: "#FFFFFF" }} />,
//   },
//   {
//     name: 'Technical MCQs',
//     subtopics: [
//       { name: 'Networking Questions', path: '/networking' },
//       { name: 'Database Questions', path: '/database' },
//       { name: 'Operating System', path: '/operating-system' },
//     ],
//     icon: <FontAwesomeIcon icon={faMessage} style={{ color: "#FFFFFF" }} />,
//   },
// ];

// const AptitudeDashboard = () => {
//   const [openIndex, setOpenIndex] = useState(-1);

//   const handleClick = (index) => {
//     setOpenIndex(openIndex === index ? -1 : index);
//   };

//   return (
//     <Box className="custom-dashboard">
//       <Typography variant="h5" gutterBottom className="dashboard-title">
//         Aptitude Dashboard
//       </Typography>
//       <List className="dashboard-list">
//         {dashboardItems.map((item, index) => (
//           <div key={index}>
//             <ListItem
//               button
//               onClick={() => item.subtopics ? handleClick(index) : null}
//               component={item.subtopics ? 'div' : Link}
//               to={item.subtopics ? undefined : item.path}
//               className="dashboard-list-item"
//             >
//               <ListItemIcon className="dashboard-icon">{item.icon}</ListItemIcon>
//               <ListItemText primary={item.name} />
//               {item.subtopics ? (openIndex === index ? <ExpandLess /> : <ExpandMore />) : null}
//             </ListItem>

//             {item.subtopics && (
//               <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
//                 <List component="div" disablePadding>
//                   {item.subtopics.map((subtopic, idx) => (
//                     <ListItem
//                       button
//                       key={idx}
//                       component={Link}
//                       to={subtopic.path}
//                       className="dashboard-sub-item"
//                       sx={{ pl: 4 }}
//                     >
//                       <ListItemText primary={subtopic.name} />
//                     </ListItem>
//                   ))}
//                 </List>
//               </Collapse>
//             )}
//           </div>
//         ))}
//       </List>
//     </Box>
//   );
// };

// export default AptitudeDashboard;

// src/components/AptitudeDashboard.js
import React from 'react';
import { List, ListItem, ListItemText, Box, Typography, ListItemIcon, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faHome } from '@fortawesome/free-solid-svg-icons'; // Import the faHome icon
import './Dashboard.css';

const topics = [
  'Problems on Trains', 'Time and Distance', 'Height and Distance',
  'Time and Work', 'Simple Interest', 'Compound Interest',
  'Profit and Loss', 'Partnership', 'Percentage',
  'Problems on Ages', 'Calendar', 'Clock',
  'Average', 'Area', 'Volume and Surface Area',
  'PnC', 'Numbers', 'Problems on Numbers',
  'H.C.F and L.C.M', 'Decimal Fraction', 'Simplification',
  'Square Root and Cube Root', 'Surds and Indices', 'Ratio and Proportion',
  'Chain Rule', 'Pipes and Cistern', 'Boats and Streams',
  'Alligation or Mixture', 'Logarithm', 'Races and Games',
  'Stocks and Shares', 'Probability'
];

const AptitudeDashboard = () => {
  return (
    <Box className="custom-dashboard">
      {/* Home Icon Button aligned to the left */}
      <Box display="flex" alignItems="center" mb={1}>
        <IconButton
          component={Link}
          to="/resource"
          style={{ color: '#b3e0ff', fontSize: '1.rem',marginTop:'30px',marginLeft:'0.5rem' }}
        >
          <FontAwesomeIcon icon={faHome} />
        </IconButton>

        <Typography variant="h5" className="dashboard-title">
          Aptitude Dashboard
        </Typography>
      </Box>
      
      <List component="nav" className="dashboard-list">
        {topics.map((topic, index) => (
          <ListItem 
            button 
            key={index} 
            component={Link} 
            to={`/topic/${topic.toLowerCase().replace(/\s+/g, '-')}`}
            className="dashboard-sub-item"
          >
            {/* Icon in front of each topic */}
            <ListItemIcon>
              <FontAwesomeIcon icon={faBook} style={{ color: "#ffffff" }} />
            </ListItemIcon>
            <ListItemText primary={topic} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AptitudeDashboard;
