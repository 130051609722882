// Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import AptitudeDashboard from './AptitudeDashboard';
import './Layout.css';

const Layout = () => {
  return (
    <div className="layout-container">
      <AptitudeDashboard />
      <div className="content-wrapper">
        <Outlet /> {/* This renders the main HTML content */}
      </div>
    </div>
  );
};

export default Layout;
