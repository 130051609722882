import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import FolderIcon from '@mui/icons-material/Folder';
import './Arithmetic.css';
import Dashboard from './Dashboard';

const topics = [
  'Problems on Trains', 'Time and Distance', 'Height and Distance',
  'Time and Work', 'Simple Interest', 'Compound Interest',
  'Profit and Loss', 'Partnership', 'Percentage',
  'Problems on Ages', 'Calendar', 'Clock',
  'Average', 'Area', 'Volume and Surface Area',
  'PnC', 'Numbers', 'Problems on Numbers',
  'H.C.F and L.C.M', 'Decimal Fraction', 'Simplification',
  'Square Root and Cube Root', 'Surds and Indices', 'Ratio and Proportion',
  'Chain Rule', 'Pipes and Cistern', 'Boats and Streams',
  'Alligation or Mixture', 'Logarithm', 'Races and Games',
  'Stocks and Shares', 'Probability'
];

const topicsWithUrls = topics.map(topic => ({
  name: topic,
  url: `/${topic.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]/g, '')}`
}));

const Arithmetic = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDashboardOpen, setDashboardOpen] = useState(!isMobile);

  const toggleDashboard = () => {
    setDashboardOpen(!isDashboardOpen);
  };

  return (
    <Box className="arithmetic-page-container">
      {/* Toggle Button for Mobile */}
      {isMobile && (
        <IconButton
          onClick={toggleDashboard}
          style={{
            position: 'fixed',
            top: '4rem',
            left: '1rem',
            zIndex: 1000,
            color: '#ffffff',
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </IconButton>
      )}

      {/* Sidebar (Dashboard) - Visible based on state */}
      {isDashboardOpen && (
        <Box className="dashboard-section">
          <Dashboard />
        </Box>
      )}

      {/* Main Content Section */}
      <Box className="content-section" style={{ flex: isDashboardOpen && !isMobile ? 4 : 1 }}>
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          align="center"
          gutterBottom
          style={{
            marginTop: isMobile ? '3.2rem' : '5rem',
            marginBottom: isMobile ? '2rem' : '3rem',
            color: 'rgba(209, 255, 5)',
          }}
        >
          Arithmetic Topics
        </Typography>

        {/* Grid of Topics */}
        <Grid container spacing={isMobile ? 1 : 2} className="topics-grid">
          {topicsWithUrls.map((topic, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2.75}
              key={index}
              className="topic-grid-item"
              style={{ marginBottom: isMobile ? '0.5rem' : '1rem' }}
            >
              <Link to={`/topic${topic.url}`} className="topic-link">
                <FolderIcon
                  className="folder-icon"
                  style={{ fontSize: isMobile ? '16px' : '20px' }}
                />
                <Typography
                  variant="body2"
                  className="topic-title"
                  style={{ fontSize: isMobile ? '12px' : '14px' }}
                >
                  {topic.name}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Arithmetic;
