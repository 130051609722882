import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const TopicContent = () => {
  const { topicName } = useParams(); // Extract the topic name from the URL
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchTopicContent = async () => {
      try {
        // Path to the HTML file in the public folder
        const response = await fetch(`/topics/${topicName}.html`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.text();
        setContent(data);
      } catch (error) {
        console.error('Error loading topic content:', error);
        setContent('<p>Content not found</p>'); // Set a fallback message if the file isn't found
      }
    };

    fetchTopicContent();
  }, [topicName]);

  return (
    <div>
      {/* <h2>{topicName.replace(/-/g, ' ').toUpperCase()}</h2> */}
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default TopicContent;
